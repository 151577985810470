/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-useless-escape */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
import { Modal, ModalBody, ModalHeader } from "react-bootstrap";
import { BiCalendarHeart } from "react-icons/bi";
import { BsChatSquareDots } from "react-icons/bs";
import { FaPrayingHands } from "react-icons/fa";
import { IoIosClose } from "react-icons/io";
import { MdOutlineMedicalServices } from "react-icons/md";
import { RiPulseFill } from "react-icons/ri";
import { RingLoader } from "react-spinners";
import { s3AwsService } from "../../../../core/services/s3Aws.service";
import { PillarDashboardEnum } from "../../../../shared/enum/dashboardEnum";
import { TThrowModalProps } from "../../../../shared/models/activities/activities.modal";
import { TPillar } from "../../../../shared/models/dashboard/pillars.model";
import { ActivitiesServices } from "../../../../shared/services/activities/activities.service";
import { MessageServices } from "../../../../shared/services/message/message.service";
import PillarService from "../../../../shared/services/pillars/pillars.service";
import { PillarCenterCard } from "../../../dashboard/components/pillar/style";
import {
  CloseBtn,
  ModalBtnAdd,
  ModalContainer,
  ModalTitle,
} from "../modal-care/style";
import { ModalPillars } from "./style";
import "./style.css";

export enum QuickTaskTypeEnum {
  Image = 1,
  Video = 2,
}

const ModalInsertTask: React.FC<TThrowModalProps> = ({
  showModal,
  closeModal,
  modalData,
  refresh,
}) => {
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState(modalData?.title || "");
  const [description, setDescription] = useState(modalData?.description || "");
  const [link, setLink] = useState(modalData?.link || "");
  const [type, setType] = useState<QuickTaskTypeEnum>(
    QuickTaskTypeEnum.Video || modalData?.type
  );
  const [linkError, setLinkError] = useState("");
  const [selectedPillarId, setSelectedPillarId] = useState<string | null>(
    modalData?.pillarId || null
  );
  const [titleError, setTitleError] = useState("");
  const [descriptionError, setDescriptionError] = useState("");
  const pillarService = new PillarService();
  const [repeatTask, setRepeatTask] = useState(
    false || !!modalData?.expiresTime
  );
  const [repeatTime, setRepeatTime] = useState({
    hours: 0,
    minutes: 0,
    seconds: 0,
  });
  const [pillars, setPillars] = useState<TPillar[]>([]);
  const messageService = new MessageServices();
  const _activitiesService = new ActivitiesServices();

  const setTitleValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(event.target.value);
    setTitleError("");
  };

  const setDescriptionValue = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setDescription(event.target.value);
    setDescriptionError("");
  };

  const setLinkValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLink(event.target.value);
    setLinkError("");
  };

  const setTypeValue = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setType(parseInt(event.target.value) as QuickTaskTypeEnum);
  };

  const handleRepeatToggle = () => {
    setRepeatTask(!repeatTask);
  };

  const handleSecondsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const seconds = parseInt(event.target.value);
    setRepeatTime({ ...repeatTime, seconds });
  };

  const handleHourChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const hours = parseInt(event.target.value);
    setRepeatTime({ ...repeatTime, hours });
  };

  const handleMinuteChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const minutes = parseInt(event.target.value);
    setRepeatTime({ ...repeatTime, minutes });
  };

  const isYouTubeLinkValid = (url: string): boolean => {
    const youtubeRegExp = /^(https?\:\/\/)?(www\.youtube\.com|youtu\.?be)\/.+$/;
    return youtubeRegExp.test(url);
  };

  const isBase64Image = (url: string): boolean => {
    return url.startsWith("data:image/");
  };

  const handleImageUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setLoading(true);
    link && (await removeImage());
    const file = event.target.files && event.target.files[0];
    if (file) {
      const result = await s3AwsService.uploadFile(file);
      setLink(result);
    }
    setLoading(false);
  };

  const removeImage = async () => {
    setLoading(true);
    if (link) {
      const key = s3AwsService.extractKeyFromUrl(link);
      try {
        await s3AwsService.deleteFile(key);
        setLink("");
      } catch (error: any) {
        messageService.error("Erro ao remover imagem:", error.message);
      }
    }
    setLoading(false);
  };

  const changeClassNamePillar = (pillarTitle: string) => {
    switch (pillarTitle) {
      case PillarDashboardEnum.Essential:
        return "essential";
      case PillarDashboardEnum.Emotional:
        return "blue-puple";
      case PillarDashboardEnum.Spiritual:
        return "gray";
      case PillarDashboardEnum.Biological:
        return "green";
      case PillarDashboardEnum.Social:
        return "blue-gray";
      default:
        return "gray";
    }
  };

  const handlePillarClick = (pillarId: string) => {
    setSelectedPillarId(pillarId);
  };

  const changeIconPillar = (pillarTitle: string) => {
    switch (pillarTitle) {
      case PillarDashboardEnum.Essential:
        return <MdOutlineMedicalServices size={40} />;
      case PillarDashboardEnum.Emotional:
        return <BiCalendarHeart size={40} />;
      case PillarDashboardEnum.Spiritual:
        return <FaPrayingHands size={40} />;
      case PillarDashboardEnum.Biological:
        return <RiPulseFill size={40} />;
      case PillarDashboardEnum.Social:
        return <BsChatSquareDots size={40} />;
      default:
        return <RiPulseFill size={40} />;
    }
  };

  const getPillars = async () => {
    setLoading(true);
    const pillarsContent = await pillarService.getAllPillars();
    setPillars(pillarsContent || []);
    setSelectedPillarId(modalData?.pillarId || null);
    setLoading(false);
  };

  const saveData = async (): Promise<void> => {
    setLoading(true);
    if (type === QuickTaskTypeEnum.Video && !isYouTubeLinkValid(link || "")) {
      setLinkError(
        `O link fornecido não é válido para o tipo ${
          type === QuickTaskTypeEnum.Video ? "vídeo" : "imagem"
        }.`
      );
      setLoading(false);
      return;
    }

    if (!title || !description || !link) {
      if (!title) setTitleError("Campo Título é obrigatório");
      if (!description) setDescriptionError("Campo Descrição é obrigatório");
      if (!link) setLinkError("Campo Link é obrigatório");
      setLoading(false);
      return;
    }

    if (!selectedPillarId) {
      messageService.error("Erro", "Por favor, selecione um pilar.");
      setLoading(false);
      return;
    }
    if (!modalData) {
      await _activitiesService.createQuickTask({
        description,
        link,
        expiresTime:
          !!repeatTime.hours || !!repeatTime.minutes || !!repeatTime.seconds
            ? `${repeatTime.hours}:${repeatTime.minutes}:${repeatTime.seconds}`
            : "",
        pillarId: selectedPillarId,
        title,
        type,
      });
    }
    if (modalData) {
      await _activitiesService.editQuickTask({
        taskId: modalData?._id || "",
        description,
        link,
        expiresTime: `${repeatTime.hours}:${repeatTime.minutes}:${repeatTime.seconds}`,
        pillarId: selectedPillarId,
        title,
        type,
      });
    }
    refresh && refresh();
    messageService.success("Sucesso!", "Tarefa criada com sucesso!");
    setLoading(false);
    closeModal();
  };

  useEffect(() => {
    getPillars();
    if (showModal && modalData) {
      setTitle(modalData?.title || "");
      setSelectedPillarId(modalData?.pillarId || "");
      setDescription(modalData?.description || "");
      setLink(modalData?.link || "");
      setRepeatTask(!!modalData.expiresTime);
      setType(modalData.type);
      if (modalData.expiresTime) {
        const [hours, minutes, seconds] = modalData.expiresTime
          .split(":")
          .map(Number);
        setRepeatTime({ hours, minutes, seconds });
      }
    }
    return () => {
      setTitle("");
      setSelectedPillarId(null);
      setDescription("");
      setLink("");
    };
  }, [modalData]);

  const handleCloseModal = () => {
    link && removeImage();
    closeModal();
  };

  return (
    <Modal show={showModal} onHide={handleCloseModal} className="custom-modal ">
      <ModalContainer>
        <ModalHeader>
          <ModalTitle>
            {modalData !== null ? "Editar Tarefa" : "Adicionar Tarefa"}
          </ModalTitle>
          <CloseBtn>
            <IoIosClose
              onClick={closeModal}
              size={28}
              style={{ color: "gray", marginRight: 25, cursor: "pointer" }}
            />
          </CloseBtn>
        </ModalHeader>
        <ModalBody>
          <div className="mb-3">
            <label className="form-label shadow-none">Título *</label>
            <input
              type="text"
              className={`form-control ${titleError ? "is-invalid" : ""}`}
              value={title}
              onChange={setTitleValue}
              required
            />
            {titleError && <div className="invalid-feedback">{titleError}</div>}
          </div>
          <label className="form-label shadow-none">Pilares *</label>
          <ModalPillars className="d-flex align-items-center mb-3">
            {pillars.map((pillarCardData) => (
              <PillarCenterCard
                className={`${
                  changeClassNamePillar(pillarCardData.name) +
                  " animate__animated animate__fadeIn animate__delay-0.5s pillars"
                } ${selectedPillarId === pillarCardData._id ? "active" : ""}`}
                onClick={() => handlePillarClick(pillarCardData._id)}
              >
                {changeIconPillar(pillarCardData.name)}
              </PillarCenterCard>
            ))}
          </ModalPillars>
          {type === QuickTaskTypeEnum.Image && (
            <div className="mb-3">
              <label className="form-label shadow-none">Imagem *</label>
              <input
                type="file"
                className={`form-control ${linkError ? "is-invalid" : ""}`}
                onChange={(e) => handleImageUpload(e)}
                required
              />
              {linkError && <div className="invalid-feedback">{linkError}</div>}
            </div>
          )}

          {type === QuickTaskTypeEnum.Video && (
            <div className="mb-3">
              <label className="form-label shadow-none">Link *</label>
              <input
                type="text"
                className={`form-control ${linkError ? "is-invalid" : ""}`}
                value={link}
                onChange={setLinkValue}
                required
              />
              {linkError && <div className="invalid-feedback">{linkError}</div>}
            </div>
          )}

          {type === QuickTaskTypeEnum.Image && link && (
            <div className="mb-3">
              <img
                src={link}
                alt="Imagem"
                style={{ maxWidth: "100%", height: "auto" }}
              />
            </div>
          )}

          <div className="mb-3">
            <label className="form-label shadow-none">Descrição *</label>
            <textarea
              className={`form-control shadow-none ${
                descriptionError ? "is-invalid" : ""
              }`}
              value={description}
              onChange={setDescriptionValue}
              required
            ></textarea>
            {descriptionError && (
              <div className="invalid-feedback">{descriptionError}</div>
            )}
          </div>
          <div className="mb-3">
            <label className="form-label shadow-none">Tipo *</label>
            <select
              className="form-select"
              value={type}
              onChange={setTypeValue}
              required
            >
              <option value={QuickTaskTypeEnum.Video}>Vídeo</option>
              <option value={QuickTaskTypeEnum.Image}>Imagem</option>
            </select>
          </div>
          <div className="mb-3 form-check">
            <input
              type="checkbox"
              className="form-check-input"
              id="repeatToggle"
              checked={repeatTask}
              onChange={handleRepeatToggle}
            />
            <label className="form-check-label" htmlFor="repeatToggle">
              Essa tarefa se repete em quanto tempo?
            </label>
          </div>
          {(repeatTask || !!modalData?.expiresTime) && (
            <div className="mb-3">
              <label className="form-label shadow-none">Repetir em:</label>
              <div className="input-group">
                <input
                  type="number"
                  className="form-control"
                  placeholder="Dias"
                  min="0"
                  value={repeatTime.hours}
                  onChange={handleHourChange}
                />
                <span className="input-group-text">Horas</span>
                <input
                  type="number"
                  className="form-control"
                  placeholder="Horas"
                  min="0"
                  max="23"
                  value={repeatTime.minutes}
                  onChange={handleMinuteChange}
                />
                <span className="input-group-text">Minutos</span>
                <input
                  type="number"
                  className="form-control"
                  placeholder="Minutos"
                  min="0"
                  max="59"
                  value={repeatTime.seconds}
                  onChange={handleSecondsChange}
                />
                <span className="input-group-text">Segundos</span>
              </div>
            </div>
          )}
          {loading && (
            <div
              style={{
                position: "fixed",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                backgroundColor: "rgba(0, 0, 0, 0.5)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <RingLoader color="#00df70" loading={true} />
            </div>
          )}
          {!loading && (
            <ModalBtnAdd style={{ background: "#00DF70" }} onClick={saveData}>
              Salvar
            </ModalBtnAdd>
          )}
        </ModalBody>
      </ModalContainer>
    </Modal>
  );
};

export default ModalInsertTask;
